import React from 'react';
import axios from 'axios';
import AuthContext from '../context/auth-context';
import {Link} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import API_URL from '../API_URL';
import EachBooking from './eachBooking';



class MyBooking extends React.Component {

	static contextType = AuthContext;

	state = {renderList: [], fetched: false};
	
	componentDidMount(){
		this.fetchlist();
	}
	
	fetchlist = async () => {

		const {token,userId} = this.context;

		const response = await axios.post(API_URL,{
				query: `query{
							  bookings(userId: "${userId}"){
								    _id
								    seats
								    amount
								    user{
								      _id
								      email
								    }
								    event{
								      _id
								      title
								      description
								      date
								      price 
								    }
								    createdAt
								    updatedAt
								  }
							}`
				},
				{
					headers: {
									'Content-Type': 'application/json',
									'Authorization': 'Bearer ' + token
							}
				}

			);

		const renderList = response.data.data.bookings.map(booking => {
			return (
					<EachBooking 
						key={booking._id} 
						id={booking._id} 
						userEmail={booking.user.email}
						title={booking.event.title}
						date={booking.event.date}
						tickets={booking.seats}
						amount={booking.amount}
					/>
					);
		});

		if(renderList !== []){
			this.setState({renderList:renderList});
		}
		this.setState({fetched:true});
		

	}
	displayList(){
		
		if(!this.state.fetched){
			return (
			<div>
				<div className="card">
					<div className="card-body">
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
					</div>
					<div className="card-footer">
						<div className="row">
							<div className="col-lg-3 col-md-4"><Skeleton/></div>
							<div className="col-lg-6 col-md-4"></div>
							<div className="col-lg-3 col-md-4"><Skeleton/></div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
					</div>
					<div className="card-footer">
						<div className="row">
							<div className="col-lg-3 col-md-4"><Skeleton/></div>
							<div className="col-lg-6 col-md-4"></div>
							<div className="col-lg-3 col-md-4"><Skeleton/></div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
						<p><Skeleton/></p>
					</div>
					<div className="card-footer">
						<div className="row">
							<div className="col-lg-3 col-md-4"><Skeleton/></div>
						</div>
					</div>
				</div>


			</div>);
		}
		return this.isListEmpty();

	}
	isListEmpty = () =>{
		if(this.state.renderList.length === 0 && this.state.fetched){
			return (
					<div style={{marginTop:"45px",color: "#1d8680"}}>
						<center>
							<h1>You have no bookings currently!</h1>
							<Link to="/events" className="btn btn-warning">Book Now</Link>
						</center>
					</div>);
		}
		return <div>{this.state.renderList}</div>;
	}

	render(){
		return(
			<div className="container">
				{this.displayList()}
			</div>
			);
	}
}

export default MyBooking;