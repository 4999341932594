import React from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import Navigation from './components/Navigation';
import Home from './components/Home';
import Login from './components/Login';
import Signup from './components/Signup';
import EventList from './components/EventList';
import history from './history';
import AuthContext from './context/auth-context';
import CreateEvent from './components/CreateEvent';
import BookEvent from './components/BookEvent';
import MyBookings from './components/myBookings';
import CancelBooking from './components/CancelBooking';

class App extends React.Component{
  state= {token:null, userId:null};

  componentDidMount(){
    this.fetchLocalStorage();
  }

  fetchLocalStorage = async () => {
    try{
      let tkn = await JSON.parse(localStorage.getItem('authData')).token;
      let uid = await JSON.parse(localStorage.getItem('authData')).userId;
      if(!tkn){
        return;
      }
      await this.setState({token: tkn, userId: uid});
        
      console.log(this.state);
    }catch(err){
      return;
    }
    
  }

  login = (token, userId) => {
    this.setState({token:token, userId:userId});
    localStorage.setItem('authData', JSON.stringify(this.state));
  }
  logout = () => {
    this.setState({token:null, userId:null});
  }
  render(){
      return (
      <div>
        
        <Router history={history}>
          <AuthContext.Provider value={{token:this.state.token, userId: this.state.userId, login: this.login, logout: this.logout}}>
            <div>
              <Navigation />
              <Switch>
                <Route path="/" exact component={Home} />
                {!this.state.token && <Route path="/login" exact component={Login} />}
                {!this.state.token && <Route path="/signup" exact component={Signup} />}
                {this.state.token && <Route path="/eventcreate" exact component={CreateEvent} />}
                {this.state.token && <Route path="/book/:id" exact component={BookEvent} />}
                {this.state.token && <Route path="/mybookings" exact component={MyBookings} />}
                {this.state.token && <Route path="/cancel/:id" exact component={CancelBooking} />}
                <Route path="/events" exact component={EventList} />
              </Switch>
            </div>
          </AuthContext.Provider>
        </Router>

      </div>
    );
  }
}


export default App;
