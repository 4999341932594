import React from 'react';
import QRcode from 'qrcode.react';
import '../css/mybookings.css';
import {Link} from 'react-router-dom';

class EachBooking extends React.Component {

	state = {showQR: "none"};

	activateShowQR = async () => {
		if(this.state.showQR === "none"){
			await this.setState({showQR: "block"});
		}else{
			await this.setState({showQR: "none"});
		}
	}


	render(){
		return(
				<div className="card">
					<div className="card-body">
						<p><strong>Booking Id: </strong>{this.props.id}</p>
						<p><strong>Booked by: </strong>{this.props.userEmail}</p>
						<p><strong>Event: </strong>{this.props.title}</p>
							
								<p><strong>Date: </strong>{this.props.date}</p>
								<p><strong>Tickets: </strong>{this.props.tickets}</p>
								<p><strong>Paid: </strong>Rs{this.props.amount}</p>
							
							
								<div className="qr-button" onClick={this.activateShowQR}>
								</div>
								<div style={{display : this.state.showQR}}>
								
									<div className="glossy">
									</div>
									<div className="full-qr">
										<div className="cross" onClick={this.activateShowQR}></div>
										<div className="qr-beautify">
											<QRcode value={this.props.id} level='L' renderAs='svg' size={() => 240}/>
										</div>
									</div>
								</div>
						
					</div>
					<div className="card-footer">
						<Link className="btn btn-danger" to={() => `/cancel/${this.props.id}`}>Cancel</Link>
					</div>
				</div>
			);
	}
}

export default EachBooking;