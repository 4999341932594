import React from 'react';
import axios from 'axios';
import AuthContext from '../context/auth-context';
import {Link} from 'react-router-dom';
import '../css/cancelBooking.css';
import API_URL from '../API_URL';

class CancelBooking extends React.Component{

	state = {bookingId: this.props.match.params.id, confirmClicked: false, disabled: false};

	static contextType = AuthContext;

	confirmation = () =>{
		if(this.state.bookingId){
			return( 
				<div>
					
				</div>
				);
		}

		return( 
			<div className="cancelEnv">
				<div className="card cardify">
					<div className="card-body">
						<h4>Booking Cancelled</h4>
						<br/>
						<Link to="/mybookings" className="btn btn-warning">Back</Link>
					</div>
				</div>
			</div>
			);
	}

	finalCancel = async () => {
		this.setState({disabled: true, confirmClicked:true});

		const token = this.context.token;
		const {id} = this.props.match.params;

					await axios.post(API_URL, {
											query: `mutation{
														  cancelBooking(bookingId:"${id}"){
														    title
														  }
														}`
											},{
												headers: {
													'Content-Type': 'application/json',
													'Authorization': 'Bearer ' + token
												}
											});
		this.setState({bookingId:null});
	}
	render(){
		return(
			<div>
				<div className="card cardify">
				
					<div className="card-header">
						Are you sure you want to cancel your Booking?
					</div>
					<div className="card-body">
						<p><strong>Booking Id: </strong>{this.state.bookingId}</p>
					</div>
					<div className="card-footer">
				        <Link to="/mybookings" className="btn btn-warning mx-auto b1" disabled={this.state.disabled} >Cancel</Link>
				        <button type="submit" onClick={this.finalCancel} className="btn btn-danger b2" disabled={this.state.disabled}>
							{this.state.confirmClicked && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
							{!this.state.confirmClicked && <strong>Confirm</strong>}
						</button>
				     </div>

				</div>
				{this.confirmation()}
			</div>
			);
	};

}

export default CancelBooking;