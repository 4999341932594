import React from 'react';
import axios from 'axios';
import AuthContext from '../context/auth-context';
import '../css/bookEvent.css';
import {Link} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import QRcode from 'qrcode.react';
import API_URL from '../API_URL';

//background: #ffc107;
//    color: #343a40;
class BookEvent extends React.Component{

	state = {errBlock:"none", bookingId: null, title:"",amount:null, maxEntries: null,tickets:1, description:"", date:"",price:null, detailFetched: false,disabled: false, confirmClicked:false};

	static contextType = AuthContext;


	componentDidMount(){
		this.eventDetailFetch();
	}


	eventDetailFetch = async () => {
		const {id} = this.props.match.params;

		const response = await axios.post(API_URL, {
													query: `query{
															  fetchEvent(eventId:"${id}"){
															    _id
															    title
															    description
															    price
															    date
															    maxEntries
															  }
															}`
											},{
												headers: {
													'Content-Type': 'application/json'
												}
											});
		
		this.setState({
				detailFetched: true,
				title: response.data.data.fetchEvent.title,
				description: response.data.data.fetchEvent.description,
				maxEntries: response.data.data.fetchEvent.maxEntries,
				price: response.data.data.fetchEvent.price,
				date: response.data.data.fetchEvent.date
				});
		this.setState({
				amount: this.state.price
				});
	}
	confirmation = () =>{
		if(!this.state.bookingId){
			return( 
				<div>
				</div>
				);
		}

		return( 
			<div className="bookingEnv">
				<div className="card cardify">
					<div className="card-body">
						<h4>Booking Confirmed</h4>
						<QRcode value={this.state.bookingId} level='M'/>
						<h4>Booking Id: {this.state.bookingId}</h4>	
						<br/>
						<Link to="/mybookings" className="btn btn-warning">Back</Link>
					</div>
				</div>
			</div>
			);
	}

	updateTicket = async (event) => {

		if(event.target.value>0){

		await this.setState({tickets: event.target.value});

		const amountTotal = this.state.price * this.state.tickets;
		
		this.setState({amount:amountTotal});

		}
	}
	increment = () => {
		const tickets = parseInt(this.state.tickets);
		this.setState({tickets: tickets+1});
	}

	decrement = () => {
		if(parseInt(this.state.tickets)>1){
			const tickets = parseInt(this.state.tickets);
			this.setState({tickets: tickets-1});
		}
	}

	finalBooking = async () => {

		const tickets = this.state.tickets;
		const amountTotal = this.state.price * tickets;

		this.setState({disabled: true, confirmClicked:true, tickets: tickets});


		const token = this.context.token;
		const {id} = this.props.match.params;

		
		try{
		const response = await axios.post(API_URL, {
											query: `mutation{
													  bookEvent(eventId: "${id}", seats:${tickets}){
													    _id
													    createdAt
													    user{
													      _id
													      email
													    }
													    event{
													      _id
													      title
													      description
													      price
													      date
													    }
													    updatedAt
													  }
													}`
											},{
												headers: {
													'Content-Type': 'application/json',
													'Authorization': 'Bearer ' + token
												}
											});


		this.setState({
				bookingId: response.data.data.bookEvent._id,
				amount: amountTotal
				});
		}catch{
			this.setState({errBlock: "block",disabled:false, confirmClicked:false});
		}
	}
	render(){
		return(
		<div>
			<div className="card cardify">
				
					<div className="card-header">
						Confirm Booking
					</div>
					<div className="card-body">
						<div className="alert alert-danger" role="alert" style={{display:this.state.errBlock}}>
						  Soldout! No tickets left.
						</div>
						{this.state.detailFetched && <p><strong>Title: </strong>{this.state.title}</p>}
						{!this.state.detailFetched && <p><strong></strong><Skeleton/></p>}
						
						{this.state.detailFetched && <p><strong>Description: </strong>{this.state.description}</p>}
						{!this.state.detailFetched && <p><strong></strong><Skeleton/></p>}
						
						{this.state.detailFetched && <p><strong>Date: </strong>{this.state.date}</p>}
						{!this.state.detailFetched && <p><strong></strong><Skeleton/></p>}
						
						{this.state.detailFetched && <p><strong>Price: </strong>Rs{this.state.price}</p>}
						{!this.state.detailFetched && <p><strong></strong><Skeleton/></p>}

						
						<div className="ticketCount">
							
							{this.state.detailFetched && <div><p><strong>Tickets: </strong></p>
							<div className="d-inline p-2"><button className="btn btn-danger" onClick={this.decrement}>-</button></div>
							<input type="number" value={this.state.tickets} onChange={this.updateTicket}/>
							<div className="d-inline p-2"><button className="btn btn-success" onClick={this.increment}>+</button></div></div>}
							
							<div>
							{!this.state.detailFetched && <p><Skeleton/></p>}
							</div>
						</div>
						

						{this.state.detailFetched && <p><strong>Amount: </strong>Rs {this.state.amount}</p>}
						{!this.state.detailFetched && <p><strong></strong><Skeleton/></p>}
						
					</div>
					{!this.state.detailFetched && <div className="card-footer">
						
						<div className="row">
							<div className="col-lg-3 col-md-4"><Skeleton/></div>
							<div className="col-lg-6 col-md-4"></div>
							<div className="col-lg-3 col-md-4"><Skeleton/></div>
						</div>
					</div>}

				    {this.state.detailFetched &&  <div className="card-footer">
				     	<Link to="/events" className="btn btn-warning mx-auto b1" disabled={this.state.disabled} >Cancel</Link>
				        <button type="submit" onClick={this.finalBooking} className="btn btn-success b2" disabled={this.state.disabled}>
							{this.state.confirmClicked && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
							{!this.state.confirmClicked && <strong>Confirm</strong>}
						</button>
				     </div>}
			</div>

					{this.confirmation()}
		</div>
			);
	};

}

export default BookEvent;