import React from 'react';
import {Link} from 'react-router-dom';
import AuthContext from '../context/auth-context';
import '../css/navigation.css';

class Navigation extends React.Component {
	constructor(props) {
	  super(props);
	  this.state = { width: 0, sideWidth: "0px"};
	  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
	  this.updateWindowDimensions();
	  window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth});
	}

	headNavigation = () => {
		if(this.state.width < 800){
			return <div></div>;
		}
		return(
			<AuthContext.Consumer>
				{(context) => {


								return(
									<div>
										<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
										    <Link to="/" className="navbar-brand">Evento</Link>
											  

											
											<div className="nav-item active">
											    <Link className="nav-link" to="/events">Events <span className="sr-only">(current)</span></Link>
											</div>
											<div className="nav-item active">
											   {context.token && <Link to="/mybookings" className="nav-link">My Bookings</Link>}
											</div>
											<div className="nav-item active">
											  {context.token && <Link to="/eventcreate" className="nav-link ">Create Event</Link>}
											</div>
											<div className="nav-item active ml-auto">
											    {!context.token && <Link to="/login" className="btn btn-warning my-2 my-sm-0" type="submit">Login</Link>}
												{context.token && <Link to="/login" onClick={context.logout} className="btn btn-warning my-2 my-sm-0" type="submit">Logout</Link>}
											</div>
										</nav>
									</div>
									);
							}}
			</AuthContext.Consumer>
	);
	}

	sideNavigation = () => {
		if(this.state.width > 800){
			return <div></div>;
		}
		return(
			<AuthContext.Consumer>
				{(context) => {


								return(
									<div>
										<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
										    <button className="navbar-toggler" type="button" onClick={this.toggleScope} data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
											    <span className="navbar-toggler-icon"></span>
											</button>
											
											<div className="nav-item active ml-auto">
											    {!context.token && <Link to="/login" onClick={this.toggleScopeClose} className="btn btn-warning my-2 my-sm-0" type="submit">Login</Link>}
												{context.token && <Link to="/login" onClick={context.logout} className="btn btn-warning my-2 my-sm-0" type="submit">Logout</Link>}
											</div>
										</nav>

											<div className="sidenav" id="" style={{width: this.state.sideWidth}}>
												<div className="nav-item active">
													<Link to="/" onClick={this.toggleScope} className="navbar-brand side-links">Evento</Link>
												</div>
												<div className="nav-item active">
												    <Link className="side-links" to="/events" onClick={this.toggleScope} >Events <span className="sr-only">(current)</span></Link>
												</div>
												<div className="nav-item active">
												   {context.token && <Link to="/mybookings" className="side-links" onClick={this.toggleScope} >My Bookings</Link>}
												</div>
												<div className="nav-item active">
												  {context.token && <Link to="/eventcreate" className="side-links " onClick={this.toggleScope} >Create Event</Link>}
												</div>
												</div>

										
									</div>
									);
							}}
			</AuthContext.Consumer>
			);
	}

	toggleScope = () => {
		if(this.state.sideWidth === "200px"){
			this.setState({sideWidth: "0px"});
		}else{
			this.setState({sideWidth: "200px"});
		}
	}
	toggleScopeClose = () => {
		this.setState({sideWidth: "0px"});
	} 
	
	render(){
		return(
			<div>
			{this.headNavigation()}
			{this.sideNavigation()}
			</div>
		);

	}
	
}

export default Navigation;