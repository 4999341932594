import React from 'react';
import '../css/home.css';
import {Link} from 'react-router-dom';
import AuthContext from '../context/auth-context';
 


class Home extends React.Component{
	render(){
		return(
			<AuthContext.Consumer>
				{(context) => {
					return(
						<div className="home-body">
							<center>
							<div className="home">
								<h2 style={{display: "inline"}}>Welcome to Evento</h2>
							</div>
							{!context.token && <div className="login-section">
								<h2>Get the most out of our web-application.</h2>
								<Link className="btn btn-warning" to="/login">Sign In</Link>
							</div>}
							<div className="event-section">
								<h2>View currently available event</h2>
								<Link className="btn btn-warning" to="/events">Events</Link>
							</div>
							{context.token && <div className="login-section">
								<h2>Keep track of your bookings.</h2>
								<Link className="btn btn-warning" to="/mybookings">Bookings</Link>
							</div>}
							</center>
						</div>
						);
				}}
			</AuthContext.Consumer>
			);
		
	}
}

export default Home;