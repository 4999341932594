import React from 'react';
import {Link} from 'react-router-dom';
import '../css/event.css';
import AuthContext from '../context/auth-context';
import LiveEntriesTracker from './LiveEntriesTracker';

const Event = ({title, description, price, date, id, venue, type}) => {
	let past=false;
	if(type === "past"){
		past = true;
	}else{
		past = false;
	}
	return(
		<AuthContext.Consumer>
			{(context) => {
				return(
					<div className="col-md-6 col-sm-12">
						<div className="card">
						  <h5 className="card-header">{title}</h5>
						  <div className="card-body">
						    
						    <h6 className="card-text">Summary - {description}</h6>
						    <p className="card-title">{date}</p>
						    <p className="card-text"><em>Venue : {venue}</em></p>
						    <p className="card-text"><em>Price : Rs {price} (each)</em></p>

						  </div>
						  <div className="card-footer">
						  	{!past && <div className="row">
						    	<div className="col-md-6 col-sm-12 col-lg-6">
						    		{context.token && <Link to={() => `/book/${id}`} className="btn btn-warning">Book Now</Link>}
						    		{!context.token && <Link to="/login" className="btn btn-warning">BooK Now</Link>}
						    	</div>
						    	<div className="col-md-2"></div>
						    	<div className="col-md-4 col-sm-12">
						    		<div className="">
						    			<strong ><LiveEntriesTracker id={id}/></strong>
						    		</div>
						    	</div>
						    </div>}
						  </div>
						</div>
					</div>
					);
			}
		}
	</AuthContext.Consumer>);
}

export default Event;