import React from 'react';
import axios from 'axios';
import history from '../history';
import '../css/createEvent.css';
import AuthContext from '../context/auth-context';
import API_URL from '../API_URL';

class CreateEvent extends React.Component {
	state={totalTickets:10};

	static contextType = AuthContext;

	constructor(props){
		super(props);
		this.titleEl = React.createRef();
		this.descEl = React.createRef();
		this.priceEl = React.createRef();
		this.dateEl = React.createRef();
		this.venueEl = React.createRef();
		
	}

	onFormSubmit = async (event) => {
		event.preventDefault();

		const token = this.context.token;

		const title = this.titleEl.current.value;
		const desc = this.descEl.current.value;
		const price = this.priceEl.current.value;
		const date = this.dateEl.current.value;
		const venue = this.venueEl.current.value;
		const totalTickets = this.state.totalTickets;

		await axios.post(API_URL, {
											query: `mutation{
													  createEvent(eventInput: {title:"${title}",description:"${desc}",maxEntries: ${totalTickets},price:${price},date:"${date}",venue:"${venue}"}){
													    _id
													    title
													    creator{
													      email
													    }
													  }
													}`
											},{
												headers: {
													'Content-Type': 'application/json',
													'Authorization': 'Bearer ' + token
												}
											}
										);
		//console.log(response);
		history.push("/events");
	}

	onValueChange = (event) =>{
		if(event.target.value > 0){
			this.setState({totalTickets: parseInt(event.target.value)});
		}
	}
	increment = () => {
		const tickets = parseInt(this.state.totalTickets);
		this.setState({totalTickets: tickets+1});
	}

	decrement = () => {
		if(this.state.totalTickets>1){
			const tickets = parseInt(this.state.totalTickets);
			this.setState({totalTickets: tickets-1});
		}
	}

	render(){
		return(
			<div className="container"><br/>
				<center><h2>Create Event Form</h2></center>
					<div className="box-form">

					<form className="form" style={{marginTop:"35px"}} onSubmit={this.onFormSubmit} >
						<div className="form-row" >
							<div className="form-group col-md-2"></div>
							<div className="form-group col-md-8">
								<label htmlFor="title">Title</label>
		     					<input type="text" className="form-control" id="title" ref={this.titleEl} placeholder="Enter title of event" required/>
							</div>
							<div className="form-group col-md-2"></div>
						</div>
						<div className="form-row" >
							<div className="form-group col-md-2"></div>
							<div className="form-group col-md-5">
								<label htmlFor="description">Description</label>
		     					<textarea className="form-control" id="description" ref={this.descEl} placeholder="Enter description of event" required/>
							</div>
							<div className="form-group col-md-3">
								<label htmlFor="seats">Tickets (Maximum)</label>
								<div className="tic">
									<div className="d-inline p-2"><button className="btn btn-danger" onClick={this.decrement}>-</button></div>
		     						<input className="d-inline p-2" type="number" className="form-control" id="seats" onChange={this.onValueChange} value={this.state.totalTickets} required/>
									<div className="d-inline p-2"><button className="btn btn-success" onClick={this.increment}>+</button></div>
								</div>
							</div>
							<div className="form-group col-md-2"></div>
						</div>
						<div className="form-row" >
							<div className="form-group col-md-2"></div>
							<div className="form-group col-md-4">
								<label htmlFor="price">Price(is Rs)</label>
		     					<input type="number" className="form-control" id="price" ref={this.priceEl} placeholder="Price" required/>
							</div>
							<div className="form-group col-md-4">
								<label htmlFor="date">Date of Event</label>
		     					<input type="date" className="form-control" ref={this.dateEl} id="date" required/>
							</div>
							<div className="form-group col-md-2"></div>
						</div>
						<div className="form-row" >
							<div className="form-group col-md-2"></div>
							<div className="form-group col-md-8">
								<label htmlFor="venue">Venue</label>
		     					<input type="text" className="form-control" id="venue" ref={this.venueEl} placeholder="Enter venue of event" />
							</div>
							<div className="form-group col-md-2"></div>
						</div>
						<div className="form-row" >
						<div className="form-group col-md-2"></div>
							<div className="form-group col-md-8">
								<center><input type="submit" className="btn btn-primary" value="Create Event" /></center>
							</div>
						</div>
						<div className="form-group col-md-2"></div>
					</form>
				</div>
			</div>
			);
	}
}

export default CreateEvent;