import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import history from '../history';
import '../css/login.css';
import AuthContext from '../context/auth-context';
import API_URL from '../API_URL';

class Login extends React.Component {
	state = {loginClicked: false, loginButtonDisplay: "Login", disabled:false, errBlock: "none"};

	static contextType = AuthContext;
	
	constructor(props){
		super(props);
		this.emailEl = React.createRef();
		this.passwordEl = React.createRef();
		
	}
	


	validate = async (event) => {
		this.userClicked();
		event.preventDefault();

		const email = this.emailEl.current.value;
		const password = this.passwordEl.current.value;

		if(email.trim().length === 0 || password.length === 0){
			return;
		}

		const response = await axios.post(API_URL,
							{
								query: `query {
									login(email: "${email}", password: "${password}"){
										token
										userId
									}
								}`
							},
							{
								headers: {
									'Content-Type': 'application/json'
								}
							}
						);
		if(response.data.data.login){
		
			try{
				if(response.data.data.login.token){
					this.context.login(response.data.data.login.token, response.data.data.login.userId);
				}
				history.push("/");
				 
			}catch{
				
			}

		}else{
			this.setState({loginClicked:false, disabled:false, errBlock:"block"});
		}
	

	};

	userClicked = () =>{
		this.setState({loginClicked:true, disabled:true});
	}
	render(){
		return(
			<div className="container">
				<div className="box">
					<div className="heading">Login Form</div>
					<form className="form" onSubmit={this.validate}>
						<div className="form-group">
							<label htmlFor="email" >Email</label>
							<input type="email" ref={this.emailEl} className="form-control" required/>
						</div>
						<div className="form-group">
							<label htmlFor="password">Password</label>
							<input type="password" ref={this.passwordEl} className="form-control" required/>
						</div>
						<div className="alert alert-danger" role="alert" style={{display:this.state.errBlock}}>
						  Invalid Credentials!
						</div>
						<div className="form-group">
							<center><button type="submit" onClick={this.validate}className="btn btn-primary" disabled={this.state.disabled}>
								{this.state.loginClicked && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
								{!this.state.loginClicked && <strong>Login</strong>}
							</button></center>
						</div>
					</form>
					<div>
						<center><strong> New User? <Link to="/signup">Signup</Link></strong></center>
					</div>
				</div>
			</div>
			);
	};

}
export default Login;