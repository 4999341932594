import React from 'react';
import axios from 'axios';

import API_URL from '../API_URL';

class LiveEntriesTracker extends React.Component{
	state={entriesLeft:null};

	fetchEntries = async () =>{
		const response = await axios.post(API_URL,{
				query: `query{
							  fetchEvent(eventId:"${this.props.id}"){
							  	maxEntries
							  	currentEntries
							    
							  }
							}`
				},
				{
					headers: {
									'Content-Type': 'application/json'
							}
				}

			);
		let entriesLeft = response.data.data.fetchEvent.maxEntries - response.data.data.fetchEvent.currentEntries;
		if(entriesLeft<0){
			entriesLeft = 0;
		}
		this.setState({entriesLeft:entriesLeft});

	}

	componentDidMount(){
		this.fetchEntries();
	}
	//componentDidUpdate(){
	//	this.fetchEntries();
	//}

	render(){
		return(
			<div>
				<p style={{color:"red"}}>{this.state.entriesLeft} tickets left.</p>
			</div>
			);
	}
}

export default LiveEntriesTracker;