import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import history from '../history';
import API_URL from '../API_URL';

class SignUp extends React.Component {
	state = {disabled:false, signupClicked:false, errBlock: "none"};
	constructor(props){
		super(props);
		this.emailEl = React.createRef();
		this.passwordEl = React.createRef();
		
	}
	


	validate = async (event) => {
		event.preventDefault();
		this.setState({disabled:true, signupClicked:true});
		const email = this.emailEl.current.value;
		const password = this.passwordEl.current.value;

		if(email.trim().length === 0 || password.length === 0){
			return;
		}

		const response = await axios.post(API_URL,
							{
								query: `mutation {
									createUser(userInput: {email: "${email}", password: "${password}"}){
										_id
									}
								}`
							},
							{
								headers: {
									'Content-Type': 'application/json'
								}
							}
						);
		
		if(response.data.data.createUser){
			history.push("/login");
		}else{
			this.setState({signupClicked:false, disabled:false, errBlock:"block"});
		}
		
	};
	render(){
		return(
			<div className="container">
				<div className="box">
					<div className="heading">SignUp Form</div>
					<form className="form" onSubmit={this.validate}>
						<div className="form-group">
							<label htmlFor="email" >Email</label>
							<input type="email" ref={this.emailEl} className="form-control" required/>
						</div>
						<div className="form-group">
							<label htmlFor="password">Password</label>
							<input type="password" ref={this.passwordEl} className="form-control" required/>
						</div>
						<div className="alert alert-danger" role="alert" style={{display:this.state.errBlock}}>
						  User Already Exists!
						</div>
						<div className="form-group">
							<center><button type="submit" onClick={this.validate}className="btn btn-primary" disabled={this.state.disabled}>
								{this.state.signupClicked && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
								{!this.state.signupClicked && <strong>SignUp</strong>}
							</button></center>
						</div>
					</form>
					<div>
						<center><strong> Already a user? <Link to="/login">Login</Link></strong></center>
					</div>
				</div>
			</div>
			);
	};

}
export default SignUp;