import React from 'react';
import axios from 'axios';
import Event from './Event';
import Skeleton from 'react-loading-skeleton';
import API_URL from '../API_URL';

class EventList extends React.Component{
	state = {upcomingList: [], previousList: [], upcoming: true, past: false, fetched: false, upcomingColor: "#ffc107", pastColor: "#e8ebecb0"};
	
	componentDidMount(){
		this.fetchlist();
	}

	fetchlist = async () => {
		const response = await axios.post(API_URL,{
				query: `query{
							  events{
							    _id
							    title
							    description
							    price
							    date
							    venue
							  }
							}`
				},
				{
					headers: {
									'Content-Type': 'application/json'
							}
				}

			);

		const todayDate = new Date().getTime();


		const upcomingList = response.data.data.events.map(event => {
			let eventDate = new Date(event.date).getTime();
			if (todayDate <= eventDate){
				return <Event 
							key={event._id} 
							title={event.title} 
							description={event.description} 
							price={event.price} 
							date={event.date} 
							id={event._id}
							venue={event.venue}
							//maxEntries = {event.maxEntries}
							//currentEntries = {event.currentEntries}
						/>;
					}
				});
		const previousList = response.data.data.events.map(event => {

			let eventDate = new Date(event.date).getTime();
			if (todayDate > eventDate){
				return <Event 
							type="past"
							key={event._id} 
							title={event.title} 
							description={event.description} 
							price={event.price} 
							date={event.date} 
							id={event._id}
							venue={event.venue}
							//maxEntries = {event.maxEntries}
							//currentEntries = {event.currentEntries}
						/>;
					}
		});

		this.setState({upcomingList: upcomingList, previousList: previousList, fetched:true});

	}

	showUpcomingEvents = () => {
		this.setState({upcoming:true, past:false, upcomingColor: "#ffc107", pastColor: "#e8ebecb0"});
	}
	showPastEvents = () => {
		this.setState({upcoming:false, past:true, upcomingColor: "#e8ebecb0", pastColor: "#ffc107"});
	}
	displayList(){
		
		if(!this.state.fetched){
			return (
				<div className="row">
					<div className="col-md-6 col-sm-12">
						<div className="card">
							<h5 className="card-header"><Skeleton/></h5>
							<div className="card-body">
							    <h5 className="card-title"><Skeleton count={3} /></h5>
								<p className="card-text" style={{width:"25%"}}><Skeleton/></p>
								<p className="card-text" style={{width:"25%"}}><Skeleton/></p>
							</div>
							<div className="card-footer">
								<div className="row">
						    	<div className="col-md-6 col-sm-12 col-lg-6">
						    		<p className="card-text" style={{width:"50%"}}><Skeleton/></p>
						    	</div>
						    	<div className="col-md-2"></div>
						    	<div className="col-md-4 col-sm-12">
						    		<div className="">
						    			<p className="card-text" style={{width:"50%"}}><Skeleton/></p>
						    		</div>
						    	</div>
						    </div>
							</div>
						</div>
					</div>
					
					<div className="col-md-6 col-sm-12">
						<div className="card">
							<h5 className="card-header"><Skeleton/></h5>
							<div className="card-body">
							    <h5 className="card-title"><Skeleton count={3} /></h5>
								<p className="card-text" style={{width:"25%"}}><Skeleton/></p>
								<p className="card-text" style={{width:"25%"}}><Skeleton/></p>
							</div>
							<div className="card-footer">
								<div className="row">
						    	<div className="col-md-6 col-sm-12 col-lg-6">
						    		<p className="card-text" style={{width:"50%"}}><Skeleton/></p>
						    	</div>
						    	<div className="col-md-2"></div>
						    	<div className="col-md-4 col-sm-12">
						    		<div className="">
						    			<p className="card-text" style={{width:"50%"}}><Skeleton/></p>
						    		</div>
						    	</div>
						    </div>
							</div>
						</div>
					</div>

					<div className="col-md-6 col-sm-12">
						<div className="card">
							<h5 className="card-header"><Skeleton/></h5>
							<div className="card-body">
							    <h5 className="card-title"><Skeleton count={3} /></h5>
								<p className="card-text" style={{width:"25%"}}><Skeleton/></p>
								<p className="card-text" style={{width:"25%"}}><Skeleton/></p>
							</div>
							<div className="card-footer">
								<div className="row">
						    	<div className="col-md-6 col-sm-12 col-lg-6">
						    		<p className="card-text" style={{width:"50%"}}><Skeleton/></p>
						    	</div>
						    	<div className="col-md-2"></div>
						    	<div className="col-md-4 col-sm-12">
						    		<div className="">
						    			<p className="card-text" style={{width:"50%"}}><Skeleton/></p>
						    		</div>
						    	</div>
						    </div>
							</div>
						</div>
					</div>
				</div>
				
				);
		}

		if(this.state.past){
			return <div className="row">{this.state.previousList}</div>;
		}
		return <div className="row">{this.state.upcomingList}</div>;

	}

	render(){
		return(
			<div className="container">
				<div className="box-event" style={{textAlign:"center", marginTop:"20px", marginBottom:"10px"}}>
					<div className="btn" onClick={this.showUpcomingEvents} style={{
																			    background: this.state.upcomingColor,
																			    width: "165px",
																			    border: "#dfdfdf 1px solid",
																				borderRadius: "0px",
																				height: "50px",
																			    paddingTop: "12px",
																			    fontSize: "1.1em",
																				cursor:"pointer"}}>Upcoming Events</div>
					<div className="btn" onClick={this.showPastEvents} style={{
																			    background: this.state.pastColor,
																			    width: "165px",
																			    border: "#dfdfdf 1px solid",
																				borderRadius: "0px",
																				height: "50px",
																			    paddingTop: "12px",
																			    fontSize: "1.1em",
																				cursor:"pointer"}}>Past Events</div>
				</div>
				{this.displayList()}
			</div>
			);
	}
}

export default EventList;